.templateModal {
  &-content {
    &-container {
      &-cover {
        &-image {
          height: 100%;
          object-fit: fill;
          border-radius: 25px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }
      &-buyActionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-bottom: 20px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 25px;
        opacity: 0;
      }
    }
    &-container:hover &-container-buyActionContainer {
      opacity: 1;
    }
  }
}
