.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &-mobile {
    width: 20rem;
    border-radius: 20px;
    background-color: white;
    padding: 2px;
    &-header {
      background-color: #ededed;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px;
      &-close {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #ee6a5f;
        border: 1px solid #ce5347;
      }
      &-minimize {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #f5bd4f;
        border: 1px solid #d6a243;
      }
      &-expand {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #61c454;
        border: 1px solid #58a942;
      }
    }
  }
  &-footer {
    margin: 20px 0;
    display: flex;
    min-width: 18rem;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &-left {
      display: flex;
      align-items: center;
      gap: 10px;
      &-themeName {
        font-size: 14px;
        font-weight: 600;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      gap: 5px;
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        &--selected {
          background-color: #fcd463;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 8px;
        }
        &--selected svg path {
          stroke: black;
        }
      }
      &-wrapper svg path {
        stroke: #bababa;
      }
    }
  }
}
