.editorModal {
  &-container {
    &-content {
      min-height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &-editorWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        &-preview {
          &-profile {
            width: 7.1rem;
            height: 7.1rem;
            border-radius: 50%;
            margin-right: 10px;
          }
          &-company {
            width: 80px;
            height: 80px;
            border-radius: 5px;
            margin-right: 10px;
          }
          &-cover {
            width: 15rem;
            height: 5rem;
          }
          &-defaultImage {
            &-profile {
              width: 7.1rem;
              height: 7.1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #bdbdbd;
            }
            &-company {
              width: 80px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #bdbdbd;
              border-radius: 5px;
            }
            &-cover {
              width: 15rem;
              height: 5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #bdbdbd;
            }
          }
        }
        &-editorcontainer {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
