@media screen and (min-width: 900px) {
  .accodianSm {
    display: none;
    margin: 15px 0px 0px 15px;
  }
}
@media screen and (max-width: 899px) {
  .accodianLg {
    display: none;
  }
}
