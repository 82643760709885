.mediaGrid {
  &-imageWrapper {
    width: 50px;
    height: 50px;
    margin: 11.6px 11.6px 18px 11.6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-image {
      width: 100%;
      height: 100%;
    }
    &-name {
      font-size: 10px;
      text-transform: capitalize;
      padding-top: 5px;
      color: var(--text2);
    }
  }
}
