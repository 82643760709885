.logo {
  height: 2rem;
  margin-bottom: 1rem;
}

.filterIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 1rem;
}
.loginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
}
.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.formInput {
  height: 2rem;
  border-radius: 4px;
  outline: none;
  border: 1px solid #d8dae5;
  padding: 0 10px;
  &::placeholder {
    color: #b5b5b5a8;
  }
}
.formLabel {
  color: #474d66;
  font-size: 14px;
  font-weight: 500;
}
.formBtn {
  background-color: #fcd463;
  height: 2rem;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}
.microsoftLogin {
  width: 100%;
  height: 100px;
}
