.templateOne {
  &-cover {
    width: 100%;
    height: 5rem;
    position: relative;
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-poweredBy {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  &-details {
    margin: 0 15px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    position: relative;
    top: -2rem;
    padding: 8px;
    border-radius: 10px;
    &-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &-wrapper {
        width: 6.2rem;
        height: 6.2rem;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &-image {
          width: 5.8rem;
          height: 5.8rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &-companyLogo {
        width: 3rem;
        height: 3rem;
        &-image {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: fill;
        }
      }
    }
    &-info {
      &-container {
        margin-bottom: 5px;
        &-name {
          font-size: 18px;
          font-weight: 700;
          color: var(--text2);
        }
        &-role {
          font-size: 12px;
          font-weight: 500;
          color: var(--text2);
        }
        &-other {
          font-size: 12px;
          color: var(--text);
        }
      }
      &-contact {
        margin-bottom: 5px;
        &-phone {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-bottom: 3px;
          &-logo {
            width: 1.5rem;
            height: 1.5rem;
          }
          &-value {
            font-size: 12px;
            color: var(--text2);
            font-weight: 600;
          }
        }
        &-email {
          display: flex;
          align-items: center;
          gap: 5px;
          &-logo {
            width: 1.5rem;
            height: 1.5rem;
          }
          &-value {
            font-size: 12px;
            color: var(--text2);
            font-weight: 600;
          }
        }
      }
      &-description {
        &-value {
          font-size: 12px;
          color: var(--text2);
          font-weight: 500;
        }
      }
    }
  }
}
