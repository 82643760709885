.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 15px;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 8px;
    margin-bottom: 15px;
    &-defaultProfile {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50%;
      cursor: pointer;
      background-color: #bdbdbd;
    }
    &-profile {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50%;
      cursor: pointer;
      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &-company {
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: pointer;
      &-image {
        width: 100%;
        height: 100%;
        border-radius: 5%;
      }
    }
    &-cover {
      width: 3rem;
      height: 3rem;
      position: relative;
      cursor: pointer;
      &-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
