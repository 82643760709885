@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("../src/styles/main.scss");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

body {
  overflow-x: hidden !important;
  background-color: var(--gray1) !important;
  min-height: 100vh;
}

.layout-container {
  max-width: 1536px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.hide-field {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.fieldDisabled {
  cursor: not-allowed;
}

.errorText {
  color: rgb(211, 47, 47);
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
  font-weight: 400;
  margin: 3px 0 0;
}

.starNotation {
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
  font-weight: 400;
  margin: 3px 0 0;
}

.errorField {
  border: 1px solid rgb(211, 47, 47) !important;
}

.hide-scrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
}
