:root {
  --gray1: #f9f9f9;
  --gray2: #d6d6d6;
  --blue1: #336eed;
  --black1: #050315;
  --text: #8891b0;
  --text2: #1f2a51;
}

.MuiButtonBase-root {
  text-transform: capitalize !important;
}
