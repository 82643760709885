.templateTwo {
  height: 70vh;
  position: relative;
  &-cover {
    width: 100%;
    height: 100%;
    &-default {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-rounded {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
    &-poweredBy {
      position: absolute;
      top: 5px;
      right: 20px;
    }
  }
  &-content {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%;
    padding: 10px;
    &-profile {
      width: 5.8rem;
      height: 5.8rem;
      border-radius: 50%;
      object-fit: cover;
    }
    &-company {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: fill;
    }
    &-phoneOutline {
      width: 1.5rem;
      height: 1.5rem;
    }
    &-mediaList {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
